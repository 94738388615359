import logo from '../../img/logo.svg'
import './Header.css'

export default function Header() {
  return (
    <header>
      <div className='safe header__container'>
        <img src={logo} alt='Capapult' />
        <nav>
          <a
            href='https://twitter.com/capapult'
            target='_blank'
            rel='noreferrer'
          >
            Contact
          </a>
          <a href='/whitepaper-v2.pdf' target='_blank' rel='noreferrer'>
            Whitepaper
          </a>
          <a style={{ opacity: 0.8 }} href='#app'>
            App (coming soon)
          </a>
        </nav>
      </div>

      <div className='header__border'></div>
    </header>
  )
}
