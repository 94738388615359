import './App.css'

import Header from './components/header/Header'
import Landing from './components/landing/Landing'
// import Stats from './components/stats/Stats'
import Description from './components/description/Description'
import Bottom from './components/bottom/Bottom'
import Footer from './components/footer/Footer'

function App() {
  return <>
    <Header />
    <Landing />
    {/*<Stats />*/}
    <Description />
    <Bottom />
    <Footer />
  </>
}

export default App
