import './Description.css'

import connect from '../../img/connect.png'
import team from '../../img/team.png'
import developer from '../../img/developer.png'

export default function Description() {
  return (
    <section className='safe description'>
      <div className='description__content'>
        <div>
          <p className='description__subtitle'>A DECENTRALIZED PROTOCOL</p>
          <h2>Governance</h2>
          <p>
            CAPA Governance token holder are able to decide the future of
            Capapult.
          </p>
          <a href='/whitepaper-v2.pdf' target='_blank' rel='noreferrer' className='description__button'>
            Learn more
          </a>
        </div>
        <img src={connect} alt='connect' />
      </div>
      <div className='description__content description__content__reverse'>
        <img src={team} alt='connect' />

        <div style={{ marginLeft: '5%' }}>
          <p className='description__subtitle'>LOW VOLATILITY</p>
          <h2>Stability</h2>
          <p>
            Solidus is generated by depositing interest-earning collateral
            assets on Capapult.
          </p>
          <a href='/whitepaper-v2.pdf' target='_blank' rel='noreferrer' className='description__button'>
            Learn more
          </a>
        </div>
      </div>
      <div className='description__content'>
        <div>
          <p className='description__subtitle'>NO BORDERS</p>
          <h2>Cross Chain</h2>
          <p>
            Capapults journey starts on Terra but we are aiming for cosmos and
            beyond.
          </p>
          <a href='/whitepaper-v2.pdf' target='_blank' rel='noreferrer' className='description__button'>
            Learn more
          </a>
        </div>
        <img src={developer} alt='connect' />
      </div>
    </section>
  )
}
