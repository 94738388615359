import './Bottom.css'
import coinBottom from '../../img/coinBottom.png'
import background from '../../img/backgroundBottom.png'

export default function Bottom() {
  return (
    <section
      className='bottom'
      style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}
    >
      <img src={coinBottom} alt='Coin' className='bottom__coin' />
      <h2>Join the community</h2>
      <div className='bottom__buttons'>
        <a
          href='https://twitter.com/capapult'
          target='_blank'
          rel='noreferrer'
          className='bottom__button'
        >
          Twitter
        </a>
        <a
          href='https://terra.sc/discord'
          target='_blank'
          rel='noreferrer'
          className='bottom__button'
        >
          Discord
        </a>
      </div>
    </section>
  )
}
