import './Landing.css'
import background from '../../img/background.png'
import coinLeft from '../../img/coinLeft.png'
import coinRight from '../../img/coinRight.png'
import arrow from '../../img/arrow.svg'

export default function Landing() {
  return (
    <section className='landing'>
      <div className='landing__content'>
        <p className='landing__subtitle'>
          DISCOVER A TRULY DECENTRALIZED WORLD
        </p>
        <h1 className='landing__title'>
          A better, smarter currency
          <br />
          for everyone!
        </h1>
        <p className='landing__text'>
          We bring honesty and trust to web3, join us in our journey to
          <br />a fully decentralized ecosystem.
        </p>
        <div className='landing__buttons'>
          <a
            href='/whitepaper-v2.pdf'
            target='_blank'
            rel='noreferrer'
            className='landing__button__full'
          >
            What is CAPA?
          </a>
          {/*<a href='#' className='landing__button__border'>
          Use CAPA
        </a>*/}
        </div>
      </div>
      <img src={background} alt='background' className='landing__background' />
      <img src={coinLeft} alt='CAPA' className='landing__coin__left' />
      <img src={coinRight} alt='CAPA' className='landing__coin__right' />
      <img src={arrow} alt='arrow' className='landing__arrow' />
    </section>
  )
}
