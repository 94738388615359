import './Footer.css'
import logo from '../../img/logo.svg'

export default function Footer() {
  return (
    <footer className='safe'>
      <div className='footer__bottom'>
        <img src={logo} alt='Capapult' />
        <p>A better, smarter currency for everyone!</p>
      </div>
    </footer>
  )
}
